import './WarningDialog.css'

const WarningDialog = ({message}) => {
    return(
        <div id='hideMe' className='warning-dialog'>
            <i className="bi bi-exclamation-circle"></i>
            <span className='warningMessage'>{message}</span>
        </div>
    )
}

export default WarningDialog