import "./App.css";
import Form from "./components/Form";
import { useParams } from "react-router-dom";

function App() {
  const { eventId } = useParams();

  // if (isLoading) {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         flexDirection: "column",
  //         alignItems: "center",
  //         justifyContent: "center",
  //         color: "white",
  //         height: "100vh",
  //       }}
  //     >
  //       <LoadingSpinner
  //         style={{
  //           top: "50%",
  //           left: "50%",
  //           transform: "translate(-50%, -50%)",
  //           position: "fixed",
  //           background: "#000",
  //         }}
  //       />
  //       <br />
  //       <h1>Carregando</h1>
  //     </div>
  //   );
  // }

  return (
    <div className="App">
      <Form eventId={eventId} />
    </div>
  );
}

export default App;
