import { isAxiosError } from "axios";

/**
 * Checks if the object is a choppbot backend error
 * @param {unknown} obj
 * @returns {boolean}
 */
function isChoppbotBackendError(obj) {
  return (
    typeof obj === "object" && obj !== null && "errors" in obj && !!obj.errors
  );
}

/**
 * Checks if the object is a raw php error
 * @param {unknown} obj
 * @returns {boolean}
 */
function isRawPHPError(obj) {
  return (
    typeof obj === "object" &&
    obj !== null &&
    "message" in obj &&
    typeof obj.message === "string" &&
    "exception" in obj &&
    typeof obj.exception === "string" &&
    "file" in obj &&
    typeof obj.file === "string" &&
    "line" in obj &&
    typeof obj.line === "number" &&
    "trace" in obj &&
    Array.isArray(obj.trace)
  );
}

/**
 *
 * @param {unknown} error
 * @returns {string}
 */
export function getErrorMessage(error) {
  if (isAxiosError(error)) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const errorData = error.response.data;

      if (isChoppbotBackendError(errorData)) {
        return Object.values(errorData.errors)[0];
      }

      if (isRawPHPError(errorData)) {
        return errorData.message;
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      // console.log(error.request);
      return "Um erro inesperado ocorreu";
    } else {
      // Something happened in setting up the request that triggered an Error
      return `Erro: ${error.message}`;
    }
  }
  return "Um erro desconhecido ocorreu";
}
