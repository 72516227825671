import api from ".";

/**
 * Gets a choppbot event data
 * @param {string} eventToken the event link identifier
 */
export async function getEvent(eventToken) {
  return await api
    .get("event_forms", {
      headers: {
        "Event-Token": eventToken,
        "Token-External": "base64:c/UmY/eD44gjeRHfPY1cKhmK8XyAgHgEYcM1p/1XRU0=",
      },
    })
    .then((res) => res.data);
}
